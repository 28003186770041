import React from 'react'

const Footer = () => {
    return (
        <div className="h-12">
            <div className="container flex flex-col h-full mx-auto py-auto justify-center items-center text-center">
                <div className="order-1 sm:order-none w-11/12 sm:w-auto max-w-screen-sm inline-block text-slate-800 text-sm md:text-sm mb-2 sm:mb-0">© 2022 <a href='https://ruomi.io/' className='hover:underline hover:text-blue-600'>Ruomi ITS</a>. All rights reserved.</div>
            </div>
        </div>
    )
}

export default Footer