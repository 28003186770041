import React from 'react'

const Banner = () => {
    return (
        <div className="h-11">
            <div className="bg-white flex flex-wrap flex-nowrap justify-center items-center relative gap-3 px-4 pr-8 ms:px-8 py-3 text-center	">
                <div className="order-1 sm:order-none w-11/12 sm:w-auto max-w-screen-sm inline-block text-slate-800 text-sm md:text-sm mb-2 sm:mb-0">
									This domain is for sale. Please contact the owner: contact@ruomi.io <a className='underline ml-10' href='https://www.afternic.com/forsale/anastasiadomanova.com?traffic_id=GoDaddy_DLS&traffic_type=TDFS&utm_campaign=TDFS_GoDaddy_DLS&utm_medium=sn_affiliate_click&utm_source=TDFS'>Buy domain</a>
                </div>
            </div>
        </div>
    )
}

export default Banner