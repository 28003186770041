import React from 'react'

const Home = () => {
    return (
        <div className="container flex flex-col h-full mx-auto py-auto justify-center items-center p-4">
            <div className="sm:max-w-xl flex flex-col items-center relative">
                <h1 className="text-slate-800 text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-10">Nastia Vikto</h1>
                <p className="text-slate-800 font-bold text-center mb-5">Nastia Vikto and her new project coming soon! Don´t miss 😉 .</p>
                <div className="w-full flex flex-col sm:flex-row sm:justify-center gap-2.5">
                    <div className="flex justify-center">
                        <div className="flex justify-center lg:justify-start items-center gap-4 mt-8 sm:mt-16">
                            <span className="text-slate-600 text-sm sm:text-base font-semibold tracking-widest uppercase">Find more</span>
                            <span className="w-12 h-px bg-slate-600"></span>
                            <div className="flex gap-4">
                                <a href="https://www.linkedin.com/in/nastiavikto/" target="_blank" className="text-slate-800 hover:text-blue-700 active:text-blue-800 transition duration-100" rel="noreferrer">
                                    <svg className="w-5 h-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home