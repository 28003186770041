import React from 'react'
import Home from '../pages/Home'
import Banner from './Banner'
import Footer from './Footer'
import logos from '../resources/logos.jpg'

const Layout = () => {
    return (
        <div className="layout h-screen">
            <img src={logos} loading="lazy" alt="bg pic" className="w-full h-screen object-cover object-center absolute inset-0 blur-sm" />
            <Banner />
            <Home />
            <Footer />
        </div>
    )
}

export default Layout